import { Request } from "express";
import { Environment } from "./environment";
import { Nullable } from "./common";

export enum PandadocTemplateTag {
    ERSTE = "Erste",
    HYPO = "Hypo",
    OBERBANK = "Oberbank",
    RAIKA = "Raika",
    WUESTENROT = "Wuestenrot",
    SBAU = "Sbau",
    RAI_BAU = "RaiBau",
    VOLKSBANK = "Volksbank",

    NO_SIGNING_ORDER = "NoSigningOrder",

    GENERIC = "Generic",

    SINGLE_BORROWER = "Single",
    MULTIPLE_BORROWERS = "Multiple",

    PURCHASE = "Kauf",
    REFINANCE = "Umschuldung",
    CONSTRUCTION = "Bau",

    HOUSE = "Haus",
    APARTMENT = "Wohnung",

    NO_SECURITY = "NichtSimultan",
    SECURITY_APARTMENT = "SimultanWohnung",
    SECURITY_HOUSE = "SimultanHaus",

    ONE_SECTION = "OneSection",
    TWO_SECTIONS = "TwoSections",
    THREE_SECTIONS = "ThreeSections",
    FOUR_SECTIONS = "FourSections",
    FIVE_SECTIONS = "FiveSections",
    SIX_SECTIONS = "SixSections",
    SEVEN_SECTIONS = "SevenSections",
    EIGHT_SECTIONS = "EigthSections",
    NINE_SECTIONS = "NineSections",
    TEN_SECTIONS = "TenSections",
}

export const PANDADOC_SECTION_SIZE_TAGS: PandadocTemplateTag[] = [
    PandadocTemplateTag.ONE_SECTION,
    PandadocTemplateTag.TWO_SECTIONS,
    PandadocTemplateTag.THREE_SECTIONS,
    PandadocTemplateTag.FOUR_SECTIONS,
    PandadocTemplateTag.FIVE_SECTIONS,
    PandadocTemplateTag.SIX_SECTIONS,
    PandadocTemplateTag.SEVEN_SECTIONS,
    PandadocTemplateTag.EIGHT_SECTIONS,
    PandadocTemplateTag.NINE_SECTIONS,
    PandadocTemplateTag.TEN_SECTIONS,
];

export enum PandadocDocumentSectionStatus {
    UPLOADED = "document_sections_upload.UPLOADED",
    PROCESSED = "document_sections_upload.PROCESSED",
    ERROR = "document_sections_upload.ERROR",
}

export type PandadocTemplate = {
    id: string;
    name: string;
    date_created: Date;
    date_modified: Date;
    version: string;
};

export type PandadocCreator = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
    membership_id: string;
};

export type PandadocToken = {
    name: string;
    value: string;
};

export type PandadocRole = {
    id: string;
    name: string;
    signing_order: string;
    preassigned_person: string;
};

export type PandadocRoleField = PandadocRole & {
    type: string;
};

export type PandadocFieldType = "radio_buttons" | "text" | "checkbox" | "signature";

export type PandadocField = {
    uuid: string;
    name: string;
    title: string;
    placeholder: string;
    value: string;
    assigned_to: PandadocRoleField;
    field_id: string;
    type: PandadocFieldType;
    merge_field: string;
};

export type PandadocTemplateDetails = PandadocTemplate & {
    created_by: PandadocCreator;
    tokens: PandadocToken[];
    fields: PandadocField[];
    roles: PandadocRole[];
    tags: PandadocTemplateTag[];
};

export type PandadocRecipient = {
    id?: string;
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    signing_order?: number;
};

export type CreatePandadocDocumentFieldValue = string | boolean | number;

export type CreatePandadocDocumentFields = {
    [key: string]: {
        value: CreatePandadocDocumentFieldValue;
    };
};

export type PandadocTrueFalse = "True" | "False";

export type CreatePandadocDocumentMetadata = {
    [key: string]: any;
    "hubspot.deal_id": string;
    syncTaskId?: string;
    specialistEmail?: string;
    shouldAutomaticallySend?: PandadocTrueFalse;
    environment?: Environment;
    documentSent?: PandadocTrueFalse;
};

export type CreatePandadocDocumentBody = {
    dealId: string;
    name: string;
    template_uuid: string;
    folder_uuid: string;
    recipients: PandadocRecipient[];
    tokens: PandadocToken[];
    fields: CreatePandadocDocumentFields;
    metadata?: CreatePandadocDocumentMetadata;
    roles?: PandadocRole[];
    tags?: string[];
};

export type AppendPandadocDocumentSectionBody = CreatePandadocDocumentBody;

export type CreatePandadocDocumentBundleBody = {
    sections: CreatePandadocDocumentBody[];
    dealId: string;
    sendAfterBundling?: boolean;
};

export type CreatePandadocDocumentResponseLink = {
    rel: string;
    href: string;
    type: string;
};

export type CreatePandadocDocumentResult = {
    id: string;
    name: string;
    status: string;
    date_created: string;
    date_modified: string;
    expiration_date: string;
    version: string;
    uuid: string;
    links: CreatePandadocDocumentResponseLink[];
    info_message: string;
};

export type AppendPandadocDocumentSectionResult = {
    uuid: string;
    name: string;
    document_uuid: string;
    status: string;
    date_created: string;
    date_modified: string;
    info_message: string;
};

export type PandadocDocumentSectionDetailsResult = {
    uuid: string;
    name: string;
    document_uuid: string;
    status: PandadocDocumentSectionStatus;
    section_uuids: string[];
    date_created: string;
    date_modified: string;
    info_message: string;
};

export type CreatePandadocDocumentBundleResult = {
    info_message: string;
};

export type CreatePandadocDocumentBundlesResult = CreatePandadocDocumentBundleResult[];

export type PandadocWebhookRequestLinkedObject = {
    id: string;
    provider: string;
    entity_type: string;
    entity_id: string;
};

export enum PandadocWebhookRequestStatus {
    DOCUMENT_DRAFT = "document.draft",
    DOCUMENT_SENT = "document.sent",
    DOCUMENT_COMPLETED = "document.completed",
    DOCUMENT_VOIDED = "document.voided",
    DOCUMENT_VIEWED = "document.viewed",
}

export type PandadocWebhookRequestData = {
    id: string;
    name: string;
    date_created: string;
    date_modified: string;
    expiration_date: string;
    autonumbering_sequence_name: string;
    created_by: PandadocCreator;
    metadata: CreatePandadocDocumentMetadata;
    tokens: PandadocToken[];
    fields: CreatePandadocDocumentFields;
    status: PandadocWebhookRequestStatus;
    sent_by: PandadocCreator;
    grand_total: {
        amount: 0;
        currency: string;
    };
    template: {
        id: string;
        name: string;
    };
    version: string;
    linked_objects: PandadocWebhookRequestLinkedObject[];
};

export enum PandadocWebhookEventType {
    DOCUMENT_STATE_CHANGED = "document_state_changed",
    RECIPIENT_COMPLETED = "recipient_completed",
}

export type PandadocWebhookEvent = {
    event: PandadocWebhookEventType;
    data: PandadocWebhookRequestData;
};

export type PandadocWebhookRequest = PandadocWebhookEvent[];

export type SendPandadocDocumentBody = {
    subject?: string;
    message?: string;
    silent?: boolean;
    sender?: string;
};

type SendPandadocResponseRecipient = PandadocRecipient & {
    recipient_type: string;
    shared_link: string;
};

export type SendPandadocDocumentResponse = {
    id: string;
    name: string;
    status: string;
    date_created: string;
    date_modified: string;
    date_completed: string;
    expiration_date: string;
    version: string;
    uuid: string;
    recipients: SendPandadocResponseRecipient[];
};

export type PandadocDocument = {
    id: string;
    name: string;
    status: PandadocWebhookRequestStatus;
    date_created: string;
    date_modified: string;
    date_completed: string;
    expiration_date: string;
    version: string;
    uuid: string;
};

export type GetPandadocTemplatesRequest = Request & {
    query: {
        tags: string[] | Nullable;
    };
};

export type GetPandadocDocumentsResponse = {
    results: PandadocDocument[];
};

export type PandadocDocumentDetails = PandadocWebhookRequestData & {
    pricing: object;
    date_completed: string;
    tags: PandadocTemplateTag[];
    autonumbering_sequence_name_prefix: string;
    recipients: SendPandadocResponseRecipient[];
};

export type PandadocSigningOrderItem = {
    email: string;
    order: number;
};
